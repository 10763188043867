.title {
  font-size: 60px;
  font-weight: 400;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .title {
    margin-top: -40px;
  }
}
