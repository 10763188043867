.container {
  display: flex;
}

.wrapper {
  margin-top: 120px;
}

.description {
  color: #a3a3a3;
  max-width: 600px;
}

.emails {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  width: 200px;
  max-width: 100%;
}

.subtitle {
  font-size: 44px;
  font-weight: 700;
}

.email {
  font-size: 22px;
  margin-top: 10px;
  color: white;
  text-decoration: none;
}

@media (max-width: 700px) {
  .title {
    font-size: 40px;
  }
  .email {
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .email {
    font-size: 20px;
  }
}

@media (max-width: 490px) {
  .title {
    font-size: 36px;
  }
  .description {
    font-size: 18px;
  }
  .email {
    font-size: 18px;
  }
}

@media (max-width: 320px) {
  .title {
    font-size: 26px;
  }
}
