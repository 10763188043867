.wrapper {
  position: relative;
  overflow: hidden;
  padding: 1px;
  border-radius: 6px;
}

.card {
  display: flex;
  flex-direction: column;
  background: #020918;
  border: 1px solid #1f1f21;
  border-radius: inherit;
  padding: 16px;
  gap: 8px;
  height: 100%;
}

.title {
  display: flex;
  gap: 8px;
  align-items: center;
  color: #58a6ff;
  font-weight: 600;
}

.url {
  color: #58a6ff;
  text-decoration: none;
}

.url:hover,
.url:focus {
  text-decoration: underline;
}

.docs {
  color: #8b949e;
  border: 1px solid #30363d;
  padding: 0.24em 0.8em;
  font-size: 11px;
  border-radius: 2rem;
  text-decoration: none;
  transition: all 200ms ease-in-out;
  word-wrap: break-word;
}

.docs:hover,
.docs:focus {
  color: white;
  border-color: #52585f;
}

.description {
  font-size: 12px;
  font-weight: 200;
  color: #8b949e;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.spacer {
  flex: 1 1 auto;
}

.footer {
  display: flex;
  gap: 16px;
  color: #8b949e;
  font-weight: 400;
  font-size: 12px;
}

.info {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #8b949e;
  text-decoration: none;
  font-weight: 200;
}

a.info:hover,
a.info:focus {
  color: #58a6ff;
}

a.info:hover *,
a.info:focus * {
  stroke: #58a6ff;
}

.language {
  display: block;
  background: #238636;
  content: "";
  position: relative;
  width: 12px;
  height: 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.card svg {
  max-width: 16px;
  max-height: 16px;
  fill: #8b949e;
  stroke: #8b949e;
}

.glow {
  position: absolute;
  display: block;
  top: -50%;
  left: -50%;
  z-index: -9;
  display: block;
  height: 200%;
  width: 200%;
  overflow: hidden;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0) 40%,
    #ecd08c 50%,
    #ecd08c 55%,
    rgba(255, 255, 255, 0) 70%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 200% auto;
  border-radius: inherit;
  animation: shine 3s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

@media (max-width: 500px) or (max-height: 700px) {
  .card {
    padding: 11px 14px;
  }
  .title {
    font-size: 13px;
  }
  .docs {
    font-size: 10px;
  }
  .description {
    font-size: 11px;
    margin-bottom: -5px;
  }
  .info {
    font-size: 11px;
  }
  .language {
    width: 10px;
    height: 10px;
  }
  .card svg {
    max-width: 12px;
    max-height: 12px;
  }
}
