body {
  margin: 0;
  color: white;
  background: #000;
  overscroll-behavior-y: none;
}

* {
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
  outline: none;
}

button,
a {
  cursor: pointer;
}
