.container {
  display: flex;
  align-items: center;
}

.description {
  font-size: 18px;
  color: #a3a3a3;
  font-weight: 200;
  max-width: 440px;
}

.row {
  margin: 80px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
}

.events {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.event {
  font-size: 18px;
  display: flex;
}

.eventYear {
  width: 60px;
}

.eventDescription {
  color: #a3a3a3;
  font-weight: 200;
}

@media (max-width: 800px) {
  .row {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 700px) {
  .row {
    gap: 60px;
    margin: 60px 0;
  }
  .title {
    font-size: 40px;
  }
}

@media (max-width: 490px) {
  .row {
    gap: 50px;
    margin: 50px 0;
  }
  .title {
    font-size: 36px;
  }
  .description {
    font-size: 16px;
  }
  .event {
    font-size: 16px;
  }
}

@media (max-width: 360px) {
  .row {
    gap: 40px;
  }
  .title {
    font-size: 23px;
  }
  .description {
    font-size: 14px;
  }
  .event {
    font-size: 14px;
  }
  .eventYear {
    width: 45px;
  }
}

@media (max-width: 310px) {
  .title {
    font-size: 20px;
  }
  .event {
    font-size: 12px;
  }
  .eventYear {
    width: 35px;
  }
}

@media (max-height: 700px) {
  .row {
    margin: 50px 0;
  }
}

@media (max-height: 650px) {
  .row {
    margin: 30px 0;
  }
}

@media (max-height: 600px) {
  .row {
    margin: 20px 0;
  }
}
