.container {
  position: relative;
  width: 1300px;
  height: 1200px;
  max-width: 100%;
  max-height: 100vh;
  padding: 24px 48px;
  margin: 0 auto;
}
.content {
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .container {
    padding: 20px 38px;
  }
}
