.container {
}

.routing {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: #0b0a0a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  animation: opacity 300ms forwards;
}

.btn {
  position: relative;
  overflow: hidden;
  display: inline-block;
  font-size: 25px;
  background: transparent;
  box-shadow: none;
  border: 0;
  color: white;
  font-weight: 300;
  letter-spacing: 1px;
  transition: all 0.2s ease-in;
  padding: 0 2px 5px;
}
.btn.active:after {
  transform: translate3d(0, 0, 0);
}

.btn:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;

  position: absolute;
  left: 0;
  bottom: 0;

  transform: translate3d(-110%, 0, 0);
  transition: all 0.2s ease-in;
  background-color: rgb(255, 140, 0);
}

.btn:hover,
.btn:focus {
  opacity: 0.7;
}

/* Hamburger */

.hamburger {
  position: relative;
  z-index: 100;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}

.hamburger svg * {
  stroke: #fff;
}

.line {
  fill: none;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.open .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.open .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.open .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
