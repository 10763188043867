.container {
  display: flex;
  align-items: center;
}

.wrapper {
  max-width: 900px;
  text-align: center;
  margin: auto;
}

.row {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.title {
  font-size: 60px;
  font-weight: 500;
  margin: 0;
  max-width: 700px;
}

.subtitle {
  font-size: 16px;
  margin-top: 60px;
  color: #bdbdbd;
  font-weight: 200;
}

@media (max-width: 700px) {
  .title {
    font-size: 40px;
    margin-top: -40px;
  }
}

@media (max-width: 490px) {
  .title {
    font-size: 30px;
    margin-top: -80px;
  }
  .subtitle {
    font-size: 14px;
  }
}

@media (max-width: 360px) {
  .title {
    font-size: 23px;
  }
  .subtitle {
    font-size: 14px;
  }
}

@media (max-width: 310px) {
  .title {
    font-size: 20px;
  }
  .subtitle {
    font-size: 11px;
  }
}
