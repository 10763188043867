.container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: fit-content;
}

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.music {
}

.btn {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 30px !important;
  height: 13px !important;
  gap: 1px;
  background: transparent;
  box-shadow: none;
  border: 0;
}

.btn:hover,
.btn:focus {
  opacity: 0.7;
}

.btn span {
  display: block;
  position: relative;
  width: 3px;
  height: 100%;
  background-color: white;
  border-radius: 3px;
  animation: bounce 2.2s ease infinite alternate;
  content: "";
}

.btn span:nth-of-type(2) {
  animation-delay: -1.2s; /* Start at the end of animation */
}

.btn span:nth-of-type(3) {
  animation-delay: -3.7s; /* Start mid-way of return of animation */
}

.disabled {
}

.disabled span {
  animation: none;
  transform: scaleY(0.2);
}

.routing {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.link {
  font-size: 16px;
  background: transparent;
  box-shadow: none;
  border: 0;
  color: #a3a3a3;
  font-weight: 300;
  letter-spacing: 1px;
  text-decoration: none;
}

.link:hover,
.link:focus {
  text-decoration: underline;
}

@keyframes bounce {
  0% {
    transform: scaleY(0.9); /* start by scaling to 30% */
  }

  25% {
    transform: scaleY(0.7); /* scale up to 100% */
  }

  50% {
    transform: scaleY(0.8); /* scale down to 50% */
  }

  75% {
    transform: scaleY(0.6); /* scale down to 50% */
  }

  100% {
    transform: scaleY(1.1); /* scale down to 60% */
  }
}

@media (max-width: 500px) {
  .container {
    padding: 20px 15px;
  }
  .btn {
    width: 40px !important;
    height: 14px !important;
  }
}
