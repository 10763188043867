.title {
  font-size: 12px;
  display: flex;
  justify-content: center;
  font-weight: 300;
  color: #a3a3a3;
}

.line {
  width: 1px;
  height: 60px;
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  margin: 0 auto;
  overflow: hidden;
  background: linear-gradient(to bottom, white 50%, rgba(255, 255, 255, 0) 50%);
  background-position: 0 -60px;
  background-size: 100% 200%;
  animation: down 2.2s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;
}
@keyframes down {
  0% {
    background-position: 0 -60px;
  }
  75% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 60px;
  }
}

@media (max-width: 300px) {
  .title {
    font-size: 10px;
  }
}
