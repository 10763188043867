.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: fit-content;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding-top: 10px;
}

.spacer {
  flex: 1 1 auto;
}

.title {
  font-weight: 800;
  font-size: 20px;
  background: transparent;
  box-shadow: none;
  border: 0;
  color: white;
  position: relative;
  z-index: 100;
  transition: all 0.2s ease-in;
}

.title:hover,
.title:focus {
  opacity: 0.7;
}

.routing {
  display: flex;
  gap: 20px;
}

.btn {
  position: relative;
  overflow: hidden;
  display: inline-block;
  font-size: 16px;
  background: transparent;
  box-shadow: none;
  border: 0;
  color: white;
  font-weight: 300;
  letter-spacing: 1px;
  padding: 0 2px 7px;
  margin: 0 4px;
  transition: all 0.2s ease-in;
}

.btn.active:after {
  transform: translate3d(0, 0, 0);
}

.btn:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;

  position: absolute;
  left: 0;
  bottom: 0;

  transform: translate3d(-110%, 0, 0);
  transition: all 0.2s ease-in;
  background-color: rgb(255, 140, 0);
}

.btn:hover,
.btn:focus {
  opacity: 0.7;
}

@media (max-width: 800px) {
  .title {
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  .title {
    font-size: 16px;
  }
}

@media (max-width: 500px) {
  .container {
    padding: 20px 15px;
  }
}
