.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20000px;
  height: 20000px;
  background: radial-gradient(transparent 80px, rgb(7 6 24) 80px);
  pointer-events: none;
  transition: background 2s;
}

.title {
  font-weight: 600;
  font-size: 24px;
  margin-top: 10px;
}

.subtitle {
  color: #aaa;
  font-weight: 200;
  font-size: 18px;
}

.grow {
  transition: background 2s;
  background: radial-gradient(transparent 200px, rgb(7 6 24) 200px);
}
