.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hide {
  animation: 400ms fadeOut ease-out forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  90% {
    opacity: 0;
    transform: translateY(27px);
  }
  100% {
    opacity: 0;
    transform: translateY(30px);
  }
}
