.container {
  display: flex;
  align-items: center;
}

.contact {
  display: flex;
  justify-content: center;
}

.partners {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  max-width: 800px;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 100px;
  margin-top: 90px;
  margin-bottom: 150px;
}

.name {
  font-size: 20px;
  margin-bottom: 10px;
}

.details {
  font-size: 16px;
  color: #a3a3a3;
  font-weight: 200;
}

@media (max-width: 1000px) {
  .row {
    gap: 60px;
  }
  .details {
    font-size: 15px;
  }
}
@media (max-width: 800px) {
  .row {
    gap: 40px;
  }
}

@media (max-width: 700px) {
  .row {
    grid-template-columns: 1fr;
    gap: 20px;
    margin: 30px 0 40px;
  }
  .title {
    font-size: 40px;
  }

  .name {
    font-size: 18px;
  }
  .details {
    font-size: 14px;
  }
}

@media (max-width: 600px) or (max-height: 700px) {
  .row {
    margin: 30px 0 40px;
  }
  .name {
    font-size: 16px;
  }
  .details {
    font-size: 13px;
  }
}

@media (max-width: 490px) {
  .title {
    font-size: 36px;
  }
  .description {
    font-size: 16px;
    margin-bottom: -25px;
  }
}

@media (max-width: 360px) or (max-height: 650px) {
  .row {
    margin: 20px 0 30px;
  }
  .title {
    font-size: 23px;
  }
  .description {
    font-size: 14px;
  }
  .name {
    font-size: 15px;
  }
  .details {
    font-size: 12px;
  }
}

@media (max-width: 320px) {
  .title {
    font-size: 20px;
  }
}

@media (max-height: 700px) {
  .row {
    margin: 30px 0 40px;
  }
}

@media (max-height: 650px) {
  .row {
    margin: 20px 0 30px;
  }
}

@media (max-height: 600px) {
  .row {
    margin: 10px 0 20px;
  }
}
