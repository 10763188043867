.container {
  display: flex;
  align-items: center;
}

.row {
  margin: 70px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}

@media (max-width: 1000px) {
  .row {
    grid-template-columns: 1fr 1fr;
  }
  .row > *:nth-child(5),
  .row > *:nth-child(6) {
    display: none;
  }
}

@media (max-width: 700px) {
  .row {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .row > *:nth-child(3),
  .row > *:nth-child(4) {
    display: none;
  }
  .title {
    font-size: 40px;
  }
  .link {
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .row {
    margin: 20px 0 30px;
  }
  .link {
    font-size: 18px;
  }
}

@media (max-width: 490px) {
  .title {
    font-size: 36px;
  }
  .description {
    font-size: 16px;
  }
  .link {
    font-size: 16px;
  }
}

@media (max-width: 360px) {
  .row {
    margin: 20px 0 30px;
  }
  .title {
    font-size: 23px;
  }
  .description {
    font-size: 14px;
  }
  .link {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .title {
    font-size: 20px;
  }
}

@media (max-height: 700px) {
  .row {
    margin: 30px 0 40px;
  }
}

@media (max-height: 650px) {
  .row {
    margin: 20px 0 30px;
  }
}

@media (max-height: 600px) {
  .row {
    margin: 10px 0 20px;
  }
}
