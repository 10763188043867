.link {
  position: relative;
  overflow: hidden;
  display: inline-block;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
  color: rgb(188, 188, 188);
  text-decoration: none;
  padding-bottom: 4px;
  transition: all 0.2s ease-in;
}

.link:hover:after,
.link:focus:after {
  transform: translate3d(0, 0, 0);
}

.link:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;

  position: absolute;
  left: 0;
  bottom: 0;

  transform: translate3d(-110%, 0, 0);
  transition: all 0.2s ease-in;
  background-color: rgb(255, 140, 0);
}

.link:hover {
  color: white;
}
